<template>
  <div class="faq-wrapper">
    <FAQCard :question="'What does booking failure mean ?'">
      <span>
        A booking can be failed during search due to the unavailability of any segment of that particular flight or the fare changes of the total flight.
      </span>
    </FAQCard>
    <FAQCard :question="'What to do when a booking has been failed?'">
      <span>
        Please try to do a new search and make the booking as soon as possible. If this problem persist please contact with the support.
      </span>
    </FAQCard>
  </div>
</template>

<script>
import FAQCard from '@/views/faqs/FAQCard.vue'

export default {
  name: 'FAQS',
  components: {
    FAQCard,
  },
  data() {
    return {
      isAccordionOpen: false,
    }
  },
}
</script>
