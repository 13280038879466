<template>
  <b-card
    class="wrapper px-2"
    no-body
  >
    <div
      class="question"
      @click="isAccordionOpen = !isAccordionOpen"
    >
      <span>{{ question }}</span>
      <div
        class="toggle-icon"
        :class="isAccordionOpen ? 'active' :''"
      />
    </div>
    <b-collapse
      v-model="isAccordionOpen"
      name="collapse"
    >
      <div class="answer-wrapper">
        <div class="answer">
          <span v-if="answer">{{ answer }}</span>
          <slot />
        </div>
      </div>
    </b-collapse>
  </b-card>
</template>

<script>
export default {
  name: 'FAQ',
  props: {
    question: {
      type: String,
    },
    answer: {
      type: String,
    },
  },
  data() {
    return {
      isAccordionOpen: false,
    }
  },
}
</script>

<style lang="scss" scoped>
.wrapper {
  border-bottom: 1px solid #dbd8fd;
  .question {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 0;
    cursor: pointer;
    span {
      color: #7367f0;
      font-size: 14px;
      line-height: 22px;
      font-weight: 500;

    }
    .toggle-icon {
      width: 24px;
      height: 24px;
      border-radius: 100px;
      position: relative;
      cursor: pointer;
      &:after {
        border: solid #7367f0;
        border-width: 0 2px 2px 0;
        display: inline-block;
        padding: 4px;
        margin-left: 8px;
        content: "";
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        transition: 0.4s transform;
      }
      &.active {
        &:after {
          transform: rotate(-135deg);
        }
      }
    }
  }
  .answer {
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
    margin: 5px 0 15px;
    border-left: 4px solid #dbd8fd;
    padding-left: 10px;
  }
}
</style>
